<template>
    <div>
      <div>
        <!-- <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="Search by title" v-model="title" />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" @click="searchTitle">
              Search
            </button>
          </div>
        </div> -->
  
        <div class="form-group">
          <label for="exampleFormControlSelect1"
            ><h5 style="width: 200px">
              <i class="bi bi-person-plus"></i> 반별 출결 현황
            </h5></label
          >
          <select
            class="form-control"
            id="holiday"
            name="holiday"
            v-model="holiday"
            @change="getTotalAttend()"
          >
            <option id="" value="00">날짜를 선택하세요.</option>         
            <option
              v-for="daylist in dayLists"
              :key="daylist.dday"
              v-bind:value="daylist.dday"
              :id="daylist.dday"
              :selected="daylist.dday == daylist.cday"
            >
              {{ daylist.dday }}
            </option>
          </select>          
        </div>
      </div>
  
   <!--  재적 인원 -->
     <div style="height: 20px;"></div>
  <!--  출석 인원 -->

  <div class="card">
    <h5 class="card-header">출석인원</h5>
    <div class="card-body">
      <h5 class="card-title"></h5>
      <p class="card-text">
        <table class="table table-bordered" style="font-size: 14px;">
            <thead>
                <tr style="text-align: center; background-color:#007bff45;">
                    <th scope="col" rowspan="2"><p>학년</p></th>
                    <th scope="col" colspan="3">제적</th>
                    <th scope="col" colspan="3">출석</th>            
                    <th scope="col" rowspan="2">비율</th>
                  </tr>                
              <tr style="text-align: center; background-color:#007bff1a;">  
                <th scope="col">남</th>              
                <th scope="col">여</th>
                <th scope="col">합계</th>
                <th scope="col">남</th>
                <th scope="col">여</th>
                <th scope="col">합계</th>                
              </tr>
            </thead>
            <tbody>

              <tr v-for="item in enrollList" :key="item[0].attenddate"  style="text-align: center;">
                <td>{{ item[0].gradename}}</td>
                <td>{{ item[0].men_cnt}}</td>
                <td>{{ item[0].women_cnt}}</td>
                <td>{{ item[0].totalcnt}}</td>
                <td>{{ item[1][1] }}</td><!-- [ "1학년1반", "5", "2", "7" ] -->
                <td>{{ item[1][2] }}</td>
                <td>{{ item[1][3] }}</td>
                <td>{{  getNum(Math.ceil(( parseInt(item[1][3]) / parseInt(item[0].totalcnt)) * 100)) }}%</td>
  
              </tr>
              <tr style="text-align: center; background-color:#dcdfe345; font-weight: 600;">
                <td>합계</td>
                <td>{{ totalsum[0] }}</td>
                <td>{{ totalsum[1] }}</td>
                <td>{{ totalsum[2] }}</td>
                <td>{{ totalsum[3] }}</td>
                <td>{{ totalsum[4] }}</td>
                <td>{{ totalsum[5] }}</td>
                <td>{{  getNum(Math.ceil(( parseInt(totalsum[5]) / parseInt(totalsum[2])) * 100)) }}%</td>
              </tr>

            </tbody>
          </table>       
      </p>
    </div>
  </div>
    </div>
</template>      

<script>
    import manageService from "../services/manageService";
   
    //import $ from "jquery";
    
    export default {
      name: "list",
      component: {},
      data() {
        return {
            enrollList: {},  
            attendLists : {},          
            pageTotalCount: 0,
            page: 15,
            holiday: "00",
            dayLists: {},     
            currentDept: "",            
            id: this.$store.state.id,
            grade: this.$store.state.grade,
            phone: this.$store.state.phone,
            name: this.$store.state.name,
            teacherid: this.$store.state.teacherid,
            role: this.$store.state.role,
            token: this.$store.state.token,  
            classid: this.$store.state.classid,
            gradefullname : "",
            totalsum : [],
            

        };
      },
      created() {

      },
      computed: {
      },
      methods: {
        getNum(val) {
            if (isNaN(val)) {
              return 0;
            }
            return val;
        },
        getDayList(){      //날짜 조회
            manageService
            .getDaysList()
            .then((response) => {

            this.dayLists = response.data.slice(0, this.page);   
          // console.log("11",this.dayLists);         
            })
            .catch((e) => {
            console.log(e);
            });
            },
        getTotalAttend(){     //전체 출결 통계 

          if(this.holiday === "00") return;

            const data = {
                attenddate: this.holiday,
                classid: this.classid,
                };
  
            manageService
            .gettotalattend(data)
            .then((response) => {

            this.enrollList = response.data;   
            var men_cnt_sum = 0;
            var women_cnt_sum = 0;
            var totalcnt_sum = 0;
            var attmen_sum = 0;
            var attwomen_sum = 0;
            var atttotal_sum = 0;

            console.log(response.data);

            for(var i=0; i < response.data.length; i++){
               
              men_cnt_sum += parseInt(response.data[i][0].men_cnt);
              women_cnt_sum += parseInt(response.data[i][0].women_cnt);
              totalcnt_sum += parseInt(response.data[i][0].totalcnt);

              attmen_sum += parseInt(response.data[i][1][1]);
              attwomen_sum += parseInt(response.data[i][1][2]);
              atttotal_sum += parseInt(response.data[i][1][3]);
              //console.log("33",response.data[i][1][1]);  
            }
            this.totalsum[0] = men_cnt_sum;
            this.totalsum[1] = women_cnt_sum;
            this.totalsum[2] = totalcnt_sum;
            this.totalsum[3] = attmen_sum;
            this.totalsum[4] = attwomen_sum;
            this.totalsum[5] = atttotal_sum;

           
            })
            .catch((e) => {
            console.log(e);
            });
        },
    },   
      mounted() {
        //this.getTotalAttend(); //전체 출석
        this. getDayList(); //날짜 조회
      },
    };
    </script>
    
    <style scoped>
    .list {
      text-align: left;
      max-width: 750px;
      margin: auto;
    }
    .done {
      background: oldlace;
    }
    .card-body {
      padding: 0.5rem;
    }
    </style>
    